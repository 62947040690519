import React from 'react'
import PropTypes from 'prop-types'
import Square from './Square'

const Board = (props) => {
  function renderSquare(i) {
    return <Square
      squareId={props.shuffledSeq[i]}
      value={props.squares[i]}
      index={i}
      onClick={() => props.onClick(i)}
    />
  }

  return (
      <div className="game-board">
        <div className="board-row">
          {renderSquare(0)}
          {renderSquare(1)}
          {renderSquare(2)}
        </div>
        <div className="board-row">
          {renderSquare(3)}
          {renderSquare(4)}
          {renderSquare(5)}
        </div>
        <div className="board-row">
          {renderSquare(6)}
          {renderSquare(7)}
          {renderSquare(8)}
        </div>
      </div>    
  )
}

export default Board

Board.propTypes = {
  shuffledSeq: PropTypes.array,
  squares: PropTypes.array,
  onClick: PropTypes.func,
}