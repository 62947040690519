import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import classNames from 'classnames'
/* material-ui components */
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
// my components
import Board from './components/Board'
import PlayerStatus from './components/PlayerStatus'
import sequences from './sequences'
import algorithms from './algorithms'
import GameOptions from './components/GameOptions'
import Instructions from './components/Instructions'
import AboutDialog from './components/AboutDialog'
import GameStatusDialog from './components/GameStatus'
// style for material-ui components
import { styles } from './styles'
// other styles
import './index.css'

const getShuffledArr = arr => {
  const newArr = arr.slice()
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1))
    ;[newArr[i], newArr[rand]] = [newArr[rand], newArr[i]]
  }
  return newArr
}

const MAX_ROUNDS = 4

const gameStartProps = {
  sequenceIndex: 0,
  algorithmIndex: 0,
  shuffledSeq: [],
  history: [
    {
      squares: Array(9).fill(null),
    },
  ],
  plays: [],
  xIsNext: true,
  round: 0,
  stepNumber: 0,
  winner: null,
  delayAfterO: 2000,
  pending: false,
}

class Game extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleSequenceChanged = this.handleSequenceChanged.bind(this)
    this.handleAlgorithmChanged = this.handleAlgorithmChanged.bind(this)
    // shuffle the board then set game starting properties
    gameStartProps.shuffledSeq = getShuffledArr(
      sequences[gameStartProps.sequenceIndex]
    )
    this.state = { ...gameStartProps, isDrawerOpen: false, showInstructions: true, showAbout: false }
  }

  handleDrawerOpen = () => {
    this.setState({ isDrawerOpen: true })
  }

  handleDrawerClose = () => {
    this.setState({ isDrawerOpen: false })
  }

  handleInstructionsClose = () => {
    this.setState({ showInstructions: false })
  }

  handleInstructionsOpen = () => {
    this.setState({ showInstructions: true })
  }

  toggleAbout = () => {
    this.setState({ showAbout: !this.state.showAbout})
  }

  resetGame = () => {
    // re-shuffle the board then set game starting properties
    gameStartProps.shuffledSeq = getShuffledArr(
      sequences[this.state.sequenceIndex]
    )
    this.setState(gameStartProps)
  }

  moveAfterDelay() {
    clearInterval(this.timerID)
    const history = this.state.history
    const current = history[history.length - 1]
    const sequence = sequences[this.state.sequenceIndex]
    const algorithm = algorithms[this.state.algorithmIndex]

    let newSquares = this.movePieces(current.squares, sequence, algorithm)

    this.setState({
      history: history.concat([
        {
          squares: newSquares,
        },
      ]),
      winner: calculateWinner(newSquares),
      round: this.state.round + 1,
      stepNumber: this.state.stepNumber + 1,
      pending: false,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // Handle play after each round (X then O), so before X's next turn.
    if (
      this.state.xIsNext !== prevState.xIsNext &&
      this.state.xIsNext === true
    ) {
      this.setState({ pending: true })
      this.timerID = setInterval(
        () => this.moveAfterDelay(),
        this.state.delayAfterO
      )
    }
  }

  // given a squareId, find the next in the original sequence (sequence could be any set of characters)
  getNextId(squareId, sequence, algorithm) {
    const sequenceLen = sequence.length

    // where does this id fit in the original sequence
    const origIndex = sequence.indexOf(squareId)
    const nextIndex = algorithm.next(origIndex, sequenceLen)
    const nextInSequence = sequence[nextIndex]

    return nextInSequence
  }

  // return an array with the new position of every play
  movePieces(squares, sequence, algorithm) {
    let nextSquares = Array(9).fill(null)

    for (let i = 0; i <= 8; i++) {
      let squareValueAtIndex = squares[i] // X or O
      let squareId = this.state.shuffledSeq[i]
      let nextSquareId = this.getNextId(squareId, sequence, algorithm)
      let nextIndex = this.state.shuffledSeq.indexOf(nextSquareId)

      nextSquares[nextIndex] = squareValueAtIndex
    }

    return nextSquares
  }

  handleKeyPress(key) {
    console.log(`You pressed ${key}`)
    let squareIndex = this.state.shuffledSeq.indexOf(key)

    // if key isn't in the sequence, then we can safely ignore it
    if (squareIndex < 0) return

    this.handleClick(squareIndex)
  }

  handleClick(i) {
    // ignore if we're pending a move of pieces
    if (this.state.pending) {
      return
    }

    const history = this.state.history
    const current = history[history.length - 1]
    const squares = current.squares.slice()

    // Do nothing if this square is already filled or has a winner been declared
    if (squares[i] || this.state.winner != null || this.state.round >= 4) {
      return
    }

    // Who's turn is it?
    const player = this.state.xIsNext ? 'X' : 'O'

    // Make the play
    squares[i] = player
    // console.log(`Set square at ${this.state.shuffledSeq[i]} to ${player}`, squares)

    // Update the board and set next player's turn
    this.setState({
      history: history.concat([
        {
          squares: squares,
        },
      ]),
      xIsNext: !this.state.xIsNext,
      stepNumber: history.length,
      plays: [...this.state.plays, this.state.shuffledSeq[i]],
    })
  }

  handleSequenceChanged(changeEvent) {
    let index = parseInt(changeEvent.target.value)
    // make this the default for the next game too
    gameStartProps.sequenceIndex = index

    this.setState({
      sequenceIndex: index,
      shuffledSeq: getShuffledArr(sequences[index]),
    })
  }

  handleAlgorithmChanged(changeEvent) {
    let index = parseInt(changeEvent.target.value)
    // make this the default for the next game too
    gameStartProps.algorithmIndex = index

    this.setState({
      algorithmIndex: index,
    })
  }

  jumpTo(step) {
    this.setState({
      stepNumber: step,
      xIsNext: step % 2 === 0,
    })
  }

  render() {
    const history = this.state.history
    const current = history[this.state.stepNumber]
    const { classes, theme } = this.props
    const { isDrawerOpen, showInstructions, showAbout } = this.state

    const moves = this.state.plays.map((play, move) => {
      const player = move % 2 === 0 ? 'X' : 'O'
      return (
        <li key={move}>
          <span>
            {player} placed on {play}
          </span>
        </li>
      )
    })

    const moveMap = this.state.history.map((history, move) => {
      const blank = <span>&mdash;</span>
      const { squares } = history
      let classes =
        move % 3 === 1 ? 'xPlay' : move % 3 === 2 ? 'oPlay' : 'mapMove'
      console.log(`Move map. move is ${move}. classes = ${classes}`)
      // console.log(`${squares} after ${move} play(s)`)
      const map = squares.map((square, index) => {
        const nextLine = index % 3 === 2 ? <br /> : ''
        return (
          <span key={index}>
            <span key={index}>{square || blank}</span>
            {nextLine}
          </span>
        )
      })
      return (
        move > 0 && (
          <div key={move} className={classes}>
            {map}
          </div>
        )
      )
    })

    return (
      <div className={classes.root}>
        <CssBaseline />
        <KeyboardEventHandler
          handleFocusableElements={true}
          handleKeys={['all']}
          onKeyEvent={key => this.handleKeyPress(key)}
        />
        <AppBar
          position='fixed'
          className={classNames(classes.appBar, {
            [classes.appBarShift]: isDrawerOpen,
          })}
        >
          <Toolbar disableGutters={!isDrawerOpen}>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                isDrawerOpen && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' color='inherit' noWrap>
              Rotating Tic-Tac-Toe
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={isDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <GameOptions
              className='game-options'
              sequences={sequences}
              algorithms={algorithms}
              sequenceIndex={this.state.sequenceIndex}
              onSequenceChanged={this.handleSequenceChanged}
              algorithmIndex={this.state.algorithmIndex}
              onAlgorithmChanged={this.handleAlgorithmChanged}
              inGame={this.state.history.length > 1}
              openInstructions={this.handleInstructionsOpen}
              openAbout={this.toggleAbout}
            />
          <Divider />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: isDrawerOpen,
          })}
        >
          <Instructions open={showInstructions} onClose={this.handleInstructionsClose} />
          <AboutDialog open={showAbout} onClose={this.toggleAbout} />
          <GameStatusDialog winner={this.state.winner} round={this.state.round} maxRounds={MAX_ROUNDS} onClose={this.resetGame} />
          <div className={classes.drawerHeader} />
          <div className={classes.game}>
            <div className={classNames(classes.gamePlay, {
                [classes.playReady]: !this.state.pending,
                [classes.playDelay]: this.state.pending,
              })}
            >
              <Board
                shuffledSeq={this.state.shuffledSeq}
                squares={current.squares}
                onClick={i => this.handleClick(i)}
              />
              <PlayerStatus
                winner={this.state.winner}
                round={this.state.round}
                maxRounds={MAX_ROUNDS}
                nextPlayer={this.state.xIsNext ? 'X' : 'O'}
                handleReset={this.resetGame}
              />
            </div>
            <div className={classes.statusBoard}>
              <div className={classes.moveHistory}>
                <div className={classes.playHistory}>
                  <h3>Play History</h3>
                  <ol>{moves}</ol>
                </div>
                <div className={classes.playMap}>
                  <h3>Play Map</h3>
                  <div className='mapHeader'>
                    <div className='column'>X Play</div>
                    <div className='column'>O Play</div>
                    <div className='column'>Map Move</div>
                  </div>
                  <div>{moveMap}</div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}

// ========================================

Game.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const GameWithStyle = withStyles(styles, { withTheme: true })(Game)

ReactDOM.render(<GameWithStyle />, document.getElementById('root'))

function calculateWinner(squares) {
  let wins = []

  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ]

  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i]
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      wins.push(squares[a])
    }
  }

  if (wins.length === 0) return null
  if (wins.length === 1) return wins[0]
  return 'Tie, nobody '
}
