import React from 'react'
import PropTypes from 'prop-types'

export default function PlayerStatus(props) {
  return (
    <div className='playersTurn'>
      It&apos;s <span className={props.nextPlayer}>{props.nextPlayer}</span> Move
      
    </div>
  )
}

PlayerStatus.propTypes = {
  nextPlayer: PropTypes.string.isRequired,
}