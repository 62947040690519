const algorithms = [
  { 
    name: 'Move to Next', 
    next: function (n, len) { return (n === len -1) ?  0 : n + 1 },
  },
  {
    name: 'Move to Previous',
    next: function (n, len) { return (n === 0) ? len - 1 : n - 1 },
  },
]

export default algorithms
