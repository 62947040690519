import React from 'react'
import PropTypes from 'prop-types'

export default function Square(props) {
  let className='square '
  if (props.index >= 3) {
    className += 'square-bottom '
  }

  if (props.index % 3 === 1 || props.index % 3 === 2) {
    className += 'square-left '
  }

  return (
    /* I'm going to need something other than a button so I can display
         both the square's ID and the X/O value.
      */
    <div 
      className={className} 
      onClick={() => props.onClick(props.value)}
    >
      <div className="squareId">{props.squareId}</div>
      <div className={`squareSelection ${props.value}`}>{props.value}</div>
    </div>
  )
}

Square.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  squareId: PropTypes.string,
  index: PropTypes.number,
}
