import blue from '@material-ui/core/colors/blue'
const drawerWidth = 240

export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: blue[400],
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'black',
    color: 'white',
    padding: '20px',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  game: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
  },
  gamePlay: {
    margin: '0 2em 2em 0',
    flexGrow: 0,
  },
  statusBoard: {
    margin: '-1em 1em 0 1em',
    flexGrow: 1,
  },
  playHistory: {
    flexGrow: 1,
  },
  playMap: {
    flexGrow: 2,
  },
  playReady: {
    cursor: 'pointer',
  },
  playDelay: {
    cursor: 'wait',
  },
  moveHistory: {
    display: 'flex',
    flexDirection: 'row',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
})
