import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import InfoIcon from '@material-ui/icons/Info'
// import BuildIcon from '@material-ui/icons/Build'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import Divider from '@material-ui/core/Divider'
import '../warped.css'

export default function GameOptions(props) {
  return(
    <div className='game-options'>
        <List>
          <ListItem button onClick={props.openInstructions}>
            <ListItemIcon>
              <LiveHelpIcon />
            </ListItemIcon>
            <ListItemText>
              Instructions
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            {/* <ListItemIcon>
              <BuildIcon />
            </ListItemIcon> */}
            <ListItemText>
            <h4>Sequence Moves</h4>
              <AlgorithmOptions 
                algorithms={props.algorithms} 
                algorithmIndex={props.algorithmIndex}
                onChange={props.onAlgorithmChanged}
                inGame={props.inGame}
              />
              <h4>Sequence Labels</h4>
              <SequenceOptions 
                sequences={props.sequences} 
                sequenceIndex={props.sequenceIndex} 
                onChange={props.onSequenceChanged}
                inGame={props.inGame}
              />
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem button onClick={props.openAbout}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText>
              About
            </ListItemText>
          </ListItem>
        </List>      
    </div>
  )
}

GameOptions.propTypes = {
  sequenceIndex: PropTypes.number,
  sequences: PropTypes.array,
  onSequenceChanged: PropTypes.func.isRequired,
  algorithms: PropTypes.array,
  algorithmIndex: PropTypes.number,
  onAlgorithmChanged: PropTypes.func,
  inGame: PropTypes.bool,
  openInstructions: PropTypes.func.isRequired,
  openAbout: PropTypes.func.isRequired,
}

function SequenceOptions(props) {
  const listItems = props.sequences.map((sequence, index) => {

    const seqLabel = sequence.map((id, index) => {
      return (
        <div key={id}>
          <span key={'s' + id} className={'w' + (index*2)}>{id}</span>
          <span key={'d' + id} className={'w' + ((index*2) + 1)}>-</span>
        </div>
      )
    })

    return (
      <div key={'radio' + index}>
        <input 
          type='radio' 
          name='sequence-option' 
          value={index}
          defaultChecked={index === props.sequenceIndex} 
          onChange={props.onChange}
          disabled={props.inGame}
        />
        <label htmlFor={index}>
          <div id='warped'>
            {seqLabel}
          </div>
        </label>
        <br />
      </div>
    )
  })

  return(
    <div>
      {listItems}
    </div>
  )
}

SequenceOptions.propTypes = {
  sequences: PropTypes.array,
  sequenceIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  inGame: PropTypes.bool,
}

function AlgorithmOptions(props) {
  const listItems = props.algorithms.map((algorithm, index) => 
    <FormControlLabel 
      key={index}
      value={index.toString()}
      control={<Radio />}
      label={algorithm.name}
      disabled={props.inGame}
    />
  )

  return(
    <div>
        <FormControl component="div" >
          <RadioGroup
            aria-label="Sequence order"
            name="algorithm-option"
            value={props.algorithmIndex.toString()}
            onChange={props.onChange}
          >
          {listItems}
          </RadioGroup>
        </FormControl>
      </div>
  )
}

AlgorithmOptions.propTypes = {
  algorithms: PropTypes.array,
  algorithmIndex: PropTypes.number,
  onChange: PropTypes.func,
  inGame: PropTypes.bool,
}