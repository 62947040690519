import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogActions from '@material-ui/core/DialogActions'
import StarIcon from '@material-ui/icons/Star'
import blue from '@material-ui/core/colors/blue'
import grey from '@material-ui/core/colors/grey'

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    color: grey[50],  // not sure why this doesn't work
    backgroundColor: blue[500],
  },
}))(MuiDialogTitle)

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions)

class Instructions extends React.Component {
  handleClose = () => {
    this.props.onClose()
  }

  render() {
    return (
      <Dialog onClose={this.handleClose} aria-labelledby="instructions-title" open={this.props.open} >
        <DialogTitle id="instructions-title">Instructions</DialogTitle>
        <DialogContent>
            <DialogContentText>
              <ListItem>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText>
                  A round consists of one turn by each player.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText>
                  After each round of play, each X and O is moved to the next square in
                  the sequence.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText>
                  To win, you must have a tic-tac-toe <em>after</em> the round is complete and
                  all pieces have been moved.
                </ListItemText>
              </ListItem>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Got it!
            </Button>
          </DialogActions>
      </Dialog>
    )
  }
}

Instructions.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default Instructions
