import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogActions from '@material-ui/core/DialogActions'
import StarIcon from '@material-ui/icons/Star'
import blue from '@material-ui/core/colors/blue'
import grey from '@material-ui/core/colors/grey'

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    color: grey[50],  // not sure why this doesn't work
    backgroundColor: blue[500],
  },
}))(MuiDialogTitle)

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions)

class GameStatusDialog extends React.Component {
  handleClose = () => {
    this.props.onClose()
  }

  render() {
    const { winner, round, maxRounds } = this.props
    const showMe = winner || round >= maxRounds

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="about-title" open={showMe} >
        <DialogTitle id="about-title">Game Status</DialogTitle>
        <DialogContent>
            <DialogContentText>
              <ListItem>
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText>
                  { winner && <h2>{winner} wins</h2> }
                  { !winner && round >= maxRounds && <h2>Stalemate!</h2> }
                </ListItemText>
              </ListItem>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Try Again!
            </Button>
          </DialogActions>
      </Dialog>
    )
  }
}

GameStatusDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  // open: PropTypes.bool.isRequired,
  winner: PropTypes.string,
  round: PropTypes.number.isRequired,
  maxRounds: PropTypes.number.isRequired,
  handleReset: PropTypes.func.isRequired,
}

export default GameStatusDialog


// export default function GameStatus(props) {

// }
